<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        What happens after the crystallization process shown below in the Fundamental Skills section
        of the cinnamon project? Select all that apply.
      </p>

      <p class="mb-4 pl-3">
        <v-img style="max-width: 600px" src="/img/assignments/UCIrvine/ChemUCI1LDQ4S1Q1.png" />
      </p>

      <p v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ4S1Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'the crystals are collected by vacuum filtration', value: 'vacuum'},
        {text: 'water is added to the solution to induce more crystallization', value: 'water'},
        {text: 'the solvent is decanted away from the crystals', value: 'decanted'},
        {text: 'the mass and melting point is recorded', value: 'recorded'},
      ],
    };
  },
};
</script>
<style scoped></style>
